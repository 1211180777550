// padding
$padding-small: 10px;
$padding-medium: 20px;
$padding-large: 30px;

// margins
$margin-small: 10px;
$margin-medium: 20px;
$margin-large: 30px;

// fonts
$font-main: sans-serif;
$font-large: 50px;
$font-medium: 30px;
$font-small: 20px;

// colors
$color-main: rgb(120, 149, 212);
$color-main-dark: rgb(60, 82, 129);
$color-error: rgb(204, 52, 103);

// screen sizes
$screen-tablet: 800px;

// placeholders
%button-reset {
    border: none;
    margin: 0;
    padding: 0;
    width: auto;
    overflow: visible;

    background: transparent;

    /* inherit font & color from ancestor */
    color: inherit;
    font: inherit;

    /* Normalize `line-height`. Cannot be changed from `normal` in Firefox 4+. */
    line-height: normal;

    /* Corrects font smoothing for webkit */
    -webkit-font-smoothing: inherit;
    -moz-osx-font-smoothing: inherit;

    /* Corrects inability to style clickable `input` types in iOS */
    -webkit-appearance: none;
}