@import '../../styles/variables';

* {
    box-sizing: border-box;
}

body {
    background-color: $color-main;
}

.app-ctn {
    width: 100%;
    background-color: $color-main; 

    .app {
        @media (min-width: $screen-tablet) {
            width: 80%;
        }

        background-color: $color-main;
        width: 100%;
        margin: 0 auto;
        font-family: $font-main;


        .header {
            @media (min-width: $screen-tablet) {
                border-bottom: none;
            }

            background-color: white;
            padding: $padding-medium;
            margin-bottom: $margin-small;

            .title {
                color: $color-main-dark;
                font-size: $font-large;
                margin: 0;

                img {
                    width: 40px;
                    margin-right: $margin-small;
                }
            }
            .subtitle {
                font-size: $font-medium;
                margin: 0;
            }
        }

        .error {
            margin: 0 auto;
            background-color: $color-error;
            margin: 10px 0;
            padding: $padding-medium;
            h1 {
                color: white;
                font-size: $font-medium;
                margin: 0;
                span {
                    text-decoration: underline;
                    cursor: pointer;
                }
            }
        }

        .posts-ctn {
            width: 100%;
            background-color: white;
            .posts {
                background-color: white;
                padding: $padding-medium;
                .title {
                    font-size: $font-medium;
                    margin: 0;
                }
                .loading {
                    h1 {
                        color: $color-main-dark;
                    }
                }
                max-width: 800px;
                .post {
                    padding: $padding-large;
                    margin: $margin-medium 0;
                }
            }
        }
    }
}