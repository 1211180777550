@import '../../styles/_variables.scss';

.post {
    border: solid 1px $color-main;
    padding: $padding-small;

    .title-ctn {
        p {
            margin: 0;
            font-size: $font-medium;
            span {
                font-size: $font-small;
            }
        }
        margin-bottom: $margin-medium;
    }
    .content-ctn {
        margin-bottom: $margin-small;
        p {
            margin: 0;
            font-size: $font-small;
        }
    }
    .likes {
        margin: 0;
        cursor: pointer;
        display: inline-block;
        img {
            margin-right: $margin-small;
            width: 13px;
        }
    }
}